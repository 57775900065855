import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Clue8 = () => (
  <Layout>
    <Header /> 

    <header className="masthead8">
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Clue #8</h1>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            <br></br>
            <br></br>
            Sit with friends
            <br></br>
            beside the blue Sea.
            <br></br>
            <br></br>
            Enjoy Libations and laughter
            <br></br>
            with a side of Charcuterie.
            <br></br>
            <br></br>
            From Bourbon to beer,
            <br></br>
            more ofter it's wine.
            <br></br>
            <br></br>
            At this place you will gather 
            <br></br>
            but here you will not dine.
          </h2>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
            <a href="/hint8" className="btn btn-primary">
              Hint
            </a>
          <br></br>
          <br></br>
          <br></br>          
        </div>
      </div>
    </header>

    {/* <Subscribe /> */}

    {/* <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Clue8;
